import React from "react";
import { BaseComponent } from "../utils/BaseComponent";

import { paramToRangeValue } from "../utils/BasicFunctions";

import DollHouse from "../components/dollHouse/DollHouse";
import {
    DollHousePluginKey,
    DollHousePluginStorageInit,
    DollHousePluginStorage,
    DollHousePlugin,
} from "../components/dollHouse/DollHousePlugin";

import floorPlan from "../assets/floor-layout.png";
import logoIconDark from "../assets/icon-3d-dark.svg";
import logoIconLight from "../assets/icon-3d-light.svg";
import iconBalconLight from "../assets/icon-balcon-light.svg";
import iconBalconDark from "../assets/icon-balcon-dark.svg";
import iconBuildingLight from "../assets/icon-building-light.svg";
import iconBuildingDark from "../assets/icon-building-dark.svg";

import "./ApartmentCard.scss";
import { withRouter, RouteComponentProps } from "react-router-dom";
import ApartmentDetails from "../components/apartmentDetails/ApartmentDetails";
import Nav from "../components/Nav";
import {
    ViewerPluginStorageKey,
    ViewerPluginStorage,
    ViewerPluginStorageInit,
    ViewerPlugin,
} from "../components/viewer/ViewerPlugin";
import Viewer from "../components/viewer/Viewer";
import { FilterFields } from "../components/apartmentFilters/ApartmentFilters";

type ViewSource = "dollhouse" | "3d" | "3dbalcony" | "viewer" | "photo";

interface ApartmentCardState {
    [DollHousePluginKey]: DollHousePluginStorage;
    [ViewerPluginStorageKey]: ViewerPluginStorage;
    apartmentId: string | undefined;
    navOpen: boolean;
    viewSource: ViewSource;
}

interface ApartmentCardParams {
    apartmentId: string;
}

class ApartmentCard extends BaseComponent<RouteComponentProps<ApartmentCardParams>, ApartmentCardState> {
    state: ApartmentCardState = {
        [DollHousePluginKey]: DollHousePluginStorageInit,
        [ViewerPluginStorageKey]: ViewerPluginStorageInit,
        apartmentId: undefined,
        navOpen: false,
        viewSource: "viewer",
    };

    private dollHousePlugin = new DollHousePlugin(this, DollHousePluginKey);
    private viewerPlugin = new ViewerPlugin(this, ViewerPluginStorageKey, (o) => this.changeSearch(o));

    private filtersFromSearch = getFiltersFromSearch();

    componentDidMount() {
        this._isMounted = true;
        const { apartmentId } = this.props.match.params;
        this.setState({ apartmentId: apartmentId }, () => {
            window.setTimeout(() => {
                this.context.ApartmentConfigLoader.listen(() => {
                    this.loadCurrentDollHouse();
                    // this.loadViewer();
                });
            });
        });
    }

    render() {
        return (
            <div className="apartment-card">
                <div className="apartment-card__viewer">
                    {this.state.viewSource === "3d" && this.currentApartment ? (
                        <iframe src={this.currentApartment.view3d} className="apartment-card__iframe"></iframe>
                    ) : null}
                    {this.state.viewSource === "3dbalcony" && this.currentApartment ? (
                        <iframe src={this.currentApartment.view3dbalcony} className="apartment-card__iframe"></iframe>
                    ) : null}
                    <DollHouse
                        dollHousePlugin={this.dollHousePlugin}
                        className={this.state.viewSource === "dollhouse" ? "" : "hide"}
                    />
                    <Viewer
                        viewerPlugin={this.viewerPlugin}
                        apartments={this.apartmentIds}
                        apartmentsAvailability={this.apartmentsAvailability}
                        className={this.state.viewSource === "viewer" ? "" : "hide"}
                        hideSwitchElementsVisibility={true}
                        hideViewButtons={true}
                        selectedApartmentId={this.state.apartmentId}
                        onElementClick={(id) => {
                            this.props.history.push({
                                pathname: `/ApartmentCard/${id}`,
                                search: this.props.location.search,
                            });
                            this.setState({ apartmentId: id }, () =>
                                this.context.ApartmentConfigLoader.listen(() => this.loadCurrentDollHouse())
                            );
                        }}
                    />

                    <div className="apartment-card__left-buttons">
                        <div
                            className={`apartment-card__left-button ${
                                this.state.viewSource === "3dbalcony" ? "is-active" : ""
                            }`}
                            onClick={() => this.setState({ viewSource: "3dbalcony" })}
                        >
                            <div className="apartment-card__left-button-logo">
                                <img src={iconBalconDark} className="apartment-card__left-button-logo-img dark" />
                                <img src={iconBalconLight} className="apartment-card__left-button-logo-img light" />
                            </div>
                            <div className="apartment-card__left-button-label">
                                WIDOK <br />Z BALKONU
                            </div>
                        </div>
                        <div
                            className={`apartment-card__left-button ${
                                this.state.viewSource === "3d" ? "is-active" : ""
                            }`}
                            onClick={() => this.setState({ viewSource: "3d" })}
                        >
                            <div className="apartment-card__left-button-logo">
                                <img src={logoIconDark} className="apartment-card__left-button-logo-img dark" />
                                <img src={logoIconLight} className="apartment-card__left-button-logo-img light" />
                            </div>
                            <div className="apartment-card__left-button-label">
                                Spacer <br />W Mieszkaniu
                            </div>
                        </div>
                        <div
                            className={`apartment-card__left-button ${
                                this.state.viewSource === "dollhouse" ? "is-active" : ""
                            }`}
                            onClick={() => this.setState({ viewSource: "dollhouse" })}
                        >
                            <div className="apartment-card__left-button-icon">zoom_out_map</div>
                            <div className="apartment-card__left-button-label">
                                Widok <br />
                                Dollhouse
                            </div>
                        </div>
                        <div
                            className={`apartment-card__left-button ${
                                this.state.viewSource === "viewer" ? "is-active" : ""
                            }`}
                            onClick={() => this.setState({ viewSource: "viewer" }, () => this.loadViewer())}
                        >
                            <div className="apartment-card__left-button-logo">
                                <img src={iconBuildingDark} className="apartment-card__left-button-logo-img dark" />
                                <img src={iconBuildingLight} className="apartment-card__left-button-logo-img light" />
                            </div>
                            <div className="apartment-card__left-button-label">
                                Widok <br />
                                Budynku
                            </div>
                        </div>

                        <div
                            className={`apartment-card__left-button ${
                                this.state.viewSource === "photo" ? "is-active" : ""
                            }`}
                            onClick={() => this.setState({ viewSource: "photo" }, () => this.loadViewer())}
                        >
                            <div className="apartment-card__left-button-icon">collections</div>

                            <div className="apartment-card__left-button-label">
                                Widok <br />
                                Mieszkania
                            </div>
                        </div>

                        <div
                            className={`apartment-card__left-button `}
                            onClick={() =>
                                this.props.history.push({ pathname: "/Apartments", search: this.props.location.search })
                            }
                        >
                            <div className="apartment-card__left-button-icon">search</div>

                            <div className="apartment-card__left-button-label">
                                Wyszukiwarka <br />
                                Mieszkań
                            </div>
                        </div>
                    </div>
                </div>
                <div className="apartment-card__sidebar">
                    {this.currentApartment ? (
                        <ApartmentDetails
                            apartment={this.currentApartment}
                            goToSearch={() => {}}
                            // navOpen={() => this.setState({ navOpen: true })}
                        />
                    ) : null}
                    {this.state.navOpen ? (
                        <Nav showNavClose={true} navClose={() => this.setState({ navOpen: false })} />
                    ) : null}
                </div>
            </div>
        );
    }

    get currentApartment() {
        return this.context.ApartmentConfigLoader.apartments.find((e) => e.id === this.state.apartmentId);
    }

    private loadCurrentDollHouse() {
        if (this.currentApartment) {
            this.dollHousePlugin.loadDollHouse(
                this.currentApartment.dollHouse.id,
                this.currentApartment.dollHouse.start
            );
        }
    }

    private loadViewer() {
        if (this.currentApartment) {
            this.viewerPlugin.init({
                viewId: this.currentApartment.view.id,
                frameIndex: this.currentApartment.view.start,
            });
        }
    }

    private changeSearch(object: { [key: string]: string | number }) {
        const search = new URLSearchParams(this.props.location.search);
        const keys = Object.keys(object);
        keys.forEach((key) => {
            const value = object[key];
            search.set(key, typeof value === "number" ? value.toString() : value);
        });
        this.props.history.replace({ search: search.toString() });
    }

    get filteredApartments() {
        const propsToCheck = Object.keys(this.filtersFromSearch.props).reduce((t, key) => {
            if (this.filtersFromSearch.props[parseInt(key)]) {
                t.push(parseInt(key));
            }
            return t;
        }, [] as number[]);

        return this.context.ApartmentConfigLoader.apartments.filter(
            (a) =>
                (this.filtersFromSearch.status === 0 || a.availability === this.filtersFromSearch.status) &&
                a.area >= this.filtersFromSearch.area.min &&
                a.area <= this.filtersFromSearch.area.max &&
                a.floor >= this.filtersFromSearch.floor.min &&
                a.floor <= this.filtersFromSearch.floor.max &&
                a.rooms >= this.filtersFromSearch.rooms.min &&
                a.rooms <= this.filtersFromSearch.rooms.max &&
                propsToCheck.reduce((t, p) => t && a.props.findIndex((e) => e.id === p) !== -1, true as boolean)
        );
    }

    get apartmentIds() {
        return this.filteredApartments.reduce((object, a) => {
            object[a.id] = true;
            return object;
        }, {} as { [key: string]: boolean });
    }

    get apartmentsAvailability() {
        return this.context.ApartmentConfigLoader.apartments.reduce((object, a) => {
            object[a.id] = a.availability;
            return object;
        }, {} as { [key: string]: 1 | 2 | 3 });
    }
}

function getFiltersFromSearch(): FilterFields {
    const search = new URLSearchParams(window.location.search);
    const props = search.get("props");
    const status = search.get("status");
    const visible = search.get("visible");
    const statusFilter = status === "1" ? 1 : status === "2" ? 2 : status === "3" ? 3 : 0;

    const propsFilter = !!props && props !== "0" ? props.split("_").reduce((o, p) => ({ ...o, [p]: true }), {}) : {};

    return {
        props: {
            ...propsFilter,
        },
        area: paramToRangeValue(search.get("area"), APP_CONFIG.filtersRangeValues.area),
        floor: paramToRangeValue(search.get("floor"), APP_CONFIG.filtersRangeValues.floor),
        rooms: paramToRangeValue(search.get("rooms"), APP_CONFIG.filtersRangeValues.rooms),
        status: statusFilter,
        visibleOnly: visible === "1" ? true : false,
    };
}

export default withRouter(ApartmentCard);
