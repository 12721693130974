import React from "react";

export interface OverlayProps {
    show?: boolean;
    title?: string;
    description?: string;
    children?: React.ReactNode;
}

export const Overlay: React.FC<OverlayProps> = ({ show, title, description, children }) => {
    if (!show) {
        return null;
    }

    return (
        <div className="overlay">
            {title ? <div className="overlay__title">{title}</div> : null}
            {description ? <div className="overlay__description">{description}</div> : null}
            {children}
        </div>
    );
};
