import React from "react";

import { BasePlugin } from "../../plugin/Base";
import { RemoveChildrenFromHTMLElement, FrameDistance, FrameInRange } from "../../utils/BasicFunctions";

export type DollHousePluginKeyType = "DollHousePlugin";
export const DollHousePluginKey: DollHousePluginKeyType = "DollHousePlugin";

export interface DollHousePluginStorage {
    dollHouseId: number;
    frameIndex: number;
    scale: number;
}

export const DollHousePluginStorageInit: DollHousePluginStorage = {
    dollHouseId: 1,
    frameIndex: 0,
    scale: 1,
};

export class DollHousePlugin extends BasePlugin<DollHousePluginKeyType, DollHousePluginStorage> {
    public imagesRef = React.createRef<HTMLDivElement>();

    public animateInProgress: boolean = false;

    get currentDollHouseConfig() {
        return this.getDollHouseConfig(this.storage.dollHouseId);
    }

    public async loadDollHouse(id: number, frameIndex?: number) {
        const dollHouseConfig = this.getDollHouseConfig(id);
        if (!dollHouseConfig) return;
        const container = this.imagesRef.current;
        if (!container) return;
        RemoveChildrenFromHTMLElement(container);
        await this.parentSetStateAsync(() => ({
            dollHouseId: id,
            frameIndex:
                frameIndex !== undefined && frameIndex < dollHouseConfig.framesCount
                    ? frameIndex
                    : dollHouseConfig.frameStart ?? 0,
        }));
        await this.parent.context.DollHouseLoader.unzipQueueAddForce(id);
        container.append(
            ...window.DOLL_HOUSES[this.storage.dollHouseId].Images.map((image) => {
                image.Image.className = `image-${image.Index}`;
                image.Image.draggable = false;
                return image.Image;
            })
        );
    }

    public getDollHouseConfig(id: number) {
        return APP_CONFIG.dollHouses.find((e) => e.id === id);
    }

    public setFrameIndex(index: number) {
        this.parentSetState(() => ({ frameIndex: index }));
    }

    public scale(direction: 1 | -1) {
        this.parentSetState((p) => {
            let newScale = p.scale + direction * 0.2;
            newScale = newScale < 1 ? 1 : newScale > 2 ? 2 : newScale;
            return {
                scale: newScale,
            };
        });
    }

    public animateToFrame(index: number) {
        if (this.animateInProgress) return;
        this.animateInProgress = true;
        this.animateProcess(index);
    }

    public animateAbort() {
        this.animateInProgress = false;
    }
    private animateProcess(indexTo: number) {
        if (!this.animateInProgress) return;
        if (this.storage.frameIndex === indexTo) {
            this.animateInProgress = false;
            return;
        }
        const direction =
            FrameDistance(this.storage.frameIndex, indexTo, this.currentDollHouseConfig?.framesCount || 0) < 0 ? -1 : 1;
        this.parentSetState((p) => ({
            frameIndex: FrameInRange(p.frameIndex + direction, this.currentDollHouseConfig?.framesCount || 0),
        }));

        window.setTimeout(() => this.animateProcess(indexTo), 30);
    }

    public move(direction: 1 | -1) {
        const delta = Math.round((this.currentDollHouseConfig?.framesCount || 60) / 6);
        const frameIndex = FrameInRange(
            this.storage.frameIndex + direction * delta,
            this.currentDollHouseConfig?.framesCount || 0
        );

        this.animateToFrame(frameIndex);
    }
}
