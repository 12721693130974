import { Callback } from "../types/Generic.types";
import { BaseComponent } from "../utils/BaseComponent";

export type ParentState<TStorageKey extends string, TStorage> = {
    [T in TStorageKey]: TStorage;
};

export class Parent<TStorageKey extends string, TStorage> extends BaseComponent<
    any,
    ParentState<TStorageKey, TStorage>
> {}

export class BasePlugin<TStorageKey extends string, TStorage> {
    protected parent: Parent<TStorageKey, TStorage>;
    protected parentStorageKey: TStorageKey;

    constructor(parent: Parent<TStorageKey, TStorage>, parentStorageKey: TStorageKey) {
        this.parent = parent;
        this.parentStorageKey = parentStorageKey;
    }

    get storage(): TStorage {
        return this.parent.state[this.parentStorageKey];
    }

    protected parentSetState(
        stateFunction: (storage: TStorage) => Partial<TStorage> | TStorage | null,
        callback?: Callback
    ) {
        if (!this.parent.isMounted) return;
        this.parent.setState(
            (p) => {
                const newStorage: TStorage = {
                    ...p[this.parentStorageKey],
                    ...stateFunction(p[this.parentStorageKey]),
                };
                return {
                    ...p,
                    [this.parentStorageKey]: newStorage,
                };
            },
            () => !!callback && callback()
        );
    }

    protected parentSetStateAsync(stateFunction: (storage: TStorage) => Partial<TStorage> | TStorage | null) {
        return new Promise((resolve) => this.parentSetState(stateFunction, resolve));
    }
}
