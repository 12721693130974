import React from "react";
import { paramToRangeValue } from "../utils/BasicFunctions";

import { BaseComponent } from "../utils/BaseComponent";
import Viewer from "../components/viewer/Viewer";
import Nav from "../components/Nav";
import ApartmentList from "../components/apartmentList/ApartmentList";
import ApartmentDetails from "../components/apartmentDetails/ApartmentDetails";
import {
    ViewerPlugin,
    ViewerPluginStorage,
    ViewerPluginStorageInit,
    ViewerPluginStorageKey,
    ViewerPluginInitValues,
} from "../components/viewer/ViewerPlugin";
import logo from "../assets/logo.png";

import "./Apartments.scss";
import ApartmentNavigator, { ApartmentNavigationState } from "../components/apartmentNavigator/ApartmentNavigator";
import ApartmentFilters, { FilterFields } from "../components/apartmentFilters/ApartmentFilters";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ApartmentsButton } from "../types/Config.types";
import DollHouse from "../components/dollHouse/DollHouse";
import {
    DollHousePluginKey,
    DollHousePluginStorageInit,
    DollHousePluginStorage,
    DollHousePlugin,
} from "../components/dollHouse/DollHousePlugin";

interface ApartmentsState {
    [DollHousePluginKey]: DollHousePluginStorage;
    [ViewerPluginStorageKey]: ViewerPluginStorage;
    filters: FilterFields;
    navOpen: boolean;
    apartmentId: string | undefined;
    apartmentNavigationState: ApartmentNavigationState;
}

class Apartments extends BaseComponent<RouteComponentProps, ApartmentsState> {
    state: ApartmentsState = {
        [DollHousePluginKey]: DollHousePluginStorageInit,
        [ViewerPluginStorageKey]: ViewerPluginStorageInit,
        filters: {
            props: {},
            status: 0,
            area: {
                min: APP_CONFIG.filtersRangeValues.area.min,
                max: APP_CONFIG.filtersRangeValues.area.max,
            },
            floor: {
                min: APP_CONFIG.filtersRangeValues.floor.min,
                max: APP_CONFIG.filtersRangeValues.floor.max,
            },
            rooms: {
                min: APP_CONFIG.filtersRangeValues.rooms.min,
                max: APP_CONFIG.filtersRangeValues.rooms.max,
            },
            visibleOnly: false,
        },
        navOpen: false,
        apartmentId: undefined,
        apartmentNavigationState: "viewer",
    };

    private dollHousePlugin = new DollHousePlugin(this, DollHousePluginKey);
    private viewerPlugin = new ViewerPlugin(this, ViewerPluginStorageKey, (object) => this.changeSearch(object));

    componentDidMount() {
        this._isMounted = true;
        window.scrollTo(0, 0);

        const initValues = this.getViewerParamsFromSearch();
        window.setTimeout(() => this.viewerPlugin.init(initValues));

        this.getFiltersFromSearch();

        this.getApartmentFromSearch();
    }

    render() {
        return (
            <div className="apartments">
                <img src={logo} className="apartments__logo" />

                <div className="apartments__viewer">
                    <div className="apartments__viewer-element">
                        <ApartmentNavigator
                            visible={!!this.currentApartment}
                            currentState={this.state.apartmentNavigationState}
                            setState={(state) => this.setApartmentNavigation(state)}
                            goToSearch={() => this.goToSearch()}
                        />

                        <Viewer
                            viewerPlugin={this.viewerPlugin}
                            apartments={this.apartmentIds}
                            apartmentsAvailability={this.apartmentsAvailability}
                            selectedApartmentId={this.state.apartmentId}
                            hideSwitchElementsVisibility={!!this.currentApartment}
                            onElementClick={(id) => this.goToApartment(id)}
                        />

                        {!!this.currentApartment && this.state.apartmentNavigationState !== "viewer" ? (
                            <div className="apartments__absolute-elements">
                                {this.state.apartmentNavigationState === "dollhouse" ? (
                                    <DollHouse dollHousePlugin={this.dollHousePlugin} />
                                ) : null}

                                {this.state.apartmentNavigationState === "3d" ? (
                                    <iframe className="apartments__iframe" src={this.currentApartment.view3d} />
                                ) : null}

                                {this.state.apartmentNavigationState === "3dbalcony" ? (
                                    <iframe className="apartments__iframe" src={this.currentApartment.view3dbalcony} />
                                ) : null}

                                {this.state.apartmentNavigationState === "photo" ? null : null}
                            </div>
                        ) : null}
                    </div>
                    <div className="apartments__viewer-fake-sidebar"></div>
                </div>
                <div className="apartments__sidebar-fake-viewer"></div>
                <div className="apartments__sidebar">
                    {this.currentApartment ? (
                        <ApartmentDetails apartment={this.currentApartment} goToSearch={() => this.goToSearch()} />
                    ) : (
                        <ApartmentList
                            filters={
                                <ApartmentFilters
                                    filters={this.state.filters}
                                    filtersSet={(f) =>
                                        this.setState(
                                            (p) => ({ filters: { ...p.filters, ...f(p.filters) } }),
                                            () => this.updateSearchWithFilters()
                                        )
                                    }
                                />
                            }
                            currentButton={this.currentApartmentButton}
                            apartments={this.filteredApartmentsByVisibility}
                            apartmentHoverId={this.viewerPlugin.storage.hoverElementIndex}
                            onApartmentOver={(id) => this.viewerPlugin.hoverElement(id)}
                            onApartmentOut={(id) => this.viewerPlugin.hoverElementOut(id)}
                            navOpen={() => this.setState({ navOpen: true })}
                            openApartment={(id) => this.goToApartment(id)}
                        />
                    )}

                    {/* {this.state.navOpen ? (
                        <Nav showNavClose={true} navClose={() => this.setState({ navOpen: false })} />
                    ) : null} */}
                </div>
            </div>
        );
    }

    get currentApartment() {
        return this.context.ApartmentConfigLoader.apartments.find((e) => e.id === this.state.apartmentId);
    }

    get filteredApartmentsByVisibility() {
        const currentFrameApartmentIdObject =
            window.VIEW_FRAMES[this.viewerPlugin.storage.viewId]?.FramesByApartment[
                this.viewerPlugin.storage.staticIndex
            ] ?? {};

        return this.filteredApartments.filter(
            (a) => !this.state.filters.visibleOnly || currentFrameApartmentIdObject[a.id]
        );
    }

    get currentApartmentButton(): ApartmentsButton {
        return (
            APP_CONFIG.viewerConfig.apartmentsButtons.find((e) => e.viewId === this.viewerPlugin.storage.viewId) || {
                label: "",
                title: "",
                viewId: 0,
                buildingFilter: [],
            }
        );
    }

    get filteredApartments() {
        const propsToCheck = Object.keys(this.state.filters.props).reduce((t, key) => {
            if (this.state.filters.props[parseInt(key)]) {
                t.push(parseInt(key));
            }
            return t;
        }, [] as number[]);

        return this.context.ApartmentConfigLoader.apartments.filter(
            (a) =>
                (!this.currentApartmentButton || this.currentApartmentButton.buildingFilter.includes(a.building)) &&
                (this.state.filters.status === 0 || a.availability === this.state.filters.status) &&
                a.area >= this.state.filters.area.min &&
                a.area <= this.state.filters.area.max &&
                a.floor >= this.state.filters.floor.min &&
                a.floor <= this.state.filters.floor.max &&
                a.rooms >= this.state.filters.rooms.min &&
                a.rooms <= this.state.filters.rooms.max &&
                propsToCheck.reduce((t, p) => t && a.props.findIndex((e) => e.id === p) !== -1, true as boolean)
        );
    }

    get apartmentIds() {
        return this.filteredApartments.reduce((object, a) => {
            object[a.id] = true;
            return object;
        }, {} as { [key: string]: boolean });
    }

    get apartmentsAvailability() {
        return this.context.ApartmentConfigLoader.apartments.reduce((object, a) => {
            object[a.id] = a.availability;
            return object;
        }, {} as { [key: string]: 1 | 2 | 3 });
    }

    private changeSearch(object: { [key: string]: string | number }) {
        const search = new URLSearchParams(this.props.location.search);
        const keys = Object.keys(object);
        keys.forEach((key) => {
            const value = object[key];
            search.set(key, typeof value === "number" ? value.toString() : value);
        });
        this.props.history.replace({ search: search.toString() });
    }

    private goToApartment(apartmentId: string) {
        const search = new URLSearchParams(this.props.location.search);
        search.set("apartment", apartmentId);
        this.props.history.push({ search: search.toString() });
        this.setState({ apartmentId: apartmentId });
    }

    private goToSearch() {
        const search = new URLSearchParams(this.props.location.search);
        search.delete("apartment");
        this.props.history.push({ search: search.toString() });
        this.setState({ apartmentId: undefined, apartmentNavigationState: "viewer" });
    }

    private updateSearchWithFilters() {
        this.changeSearch({
            props:
                Object.keys(this.state.filters.props)
                    .reduce(
                        (t, key) => (this.state.filters.props[parseInt(key)] ? [...t, parseInt(key).toString()] : t),
                        [] as string[]
                    )
                    .join("_") || "0",
            status: this.state.filters.status,
            area: `${this.state.filters.area.min}_${this.state.filters.area.max}`,
            floor: `${this.state.filters.floor.min}_${this.state.filters.floor.max}`,
            rooms: `${this.state.filters.rooms.min}_${this.state.filters.rooms.max}`,
            visible: this.state.filters.visibleOnly ? "1" : "0",
        });
    }

    private getFiltersFromSearch() {
        const search = new URLSearchParams(this.props.location.search);
        const props = search.get("props");
        const status = search.get("status");
        const visible = search.get("visible");
        const statusFilter = status === "1" ? 1 : status === "2" ? 2 : status === "3" ? 3 : 0;

        this.setState((p) => {
            const propsFilter =
                !!props && props !== "0" ? props.split("_").reduce((o, p) => ({ ...o, [p]: true }), {}) : {};

            return {
                filters: {
                    ...p.filters,
                    props: {
                        ...p.filters.props,
                        ...propsFilter,
                    },
                    area: paramToRangeValue(search.get("area"), APP_CONFIG.filtersRangeValues.area),
                    floor: paramToRangeValue(search.get("floor"), APP_CONFIG.filtersRangeValues.floor),
                    rooms: paramToRangeValue(search.get("rooms"), APP_CONFIG.filtersRangeValues.rooms),
                    status: statusFilter,
                    visibleOnly: visible === "1" ? true : false,
                },
            };
        });
    }

    private getViewerParamsFromSearch() {
        const params = new URLSearchParams(this.props.location.search);
        const initValues: ViewerPluginInitValues = {};
        const f = params.get("frame");
        const v = params.get("view");
        const s = params.get("scene");
        if (f && Number.isInteger(parseInt(f))) {
            initValues.frameIndex = parseInt(f);
        }
        if (v && Number.isInteger(parseInt(v))) {
            initValues.viewId = parseInt(v);
        }
        if (s) {
            initValues.viewScene = s === "Night" ? "Night" : "Day";
        }

        return initValues;
    }

    private getApartmentFromSearch() {
        const params = new URLSearchParams(this.props.location.search);
        const a = params.get("apartment");
        if (a) {
            this.setState({ apartmentId: a });
        }
    }

    private setApartmentNavigation(state: ApartmentNavigationState) {
        this.setState({ apartmentNavigationState: state }, () => {
            if (state === "dollhouse" && !!this.currentApartment) {
                this.dollHousePlugin.loadDollHouse(
                    this.currentApartment.dollHouse.id,
                    this.currentApartment.dollHouse.start
                );
            }
        });
    }
}

export default withRouter(Apartments);
