import React from "react";

import "./FieldWrapper.scss";

export interface FieldWrapperProps {
    label: string;
}

export default class FieldWrapper extends React.Component<FieldWrapperProps> {
    render() {
        return (
            <div className="field-wrapper">
                <div className="field-wrapper__label">{this.props.label}</div>
                <div className="field-wrapper__field">{this.props.children}</div>
            </div>
        );
    }
}
