import axios, { Canceler, AxiosResponse } from "axios";
import JSZip from "jszip";

import { ContextStorage, BaseContext } from "./Base";
import {
    ViewFramesStorageObject,
    WindowViewFramesData,
    ViewFramesQueueElement,
    ViewFrameByApartment,
} from "./ViewFramesLoader.types";
import { ProgressState } from "../types/Generic.types";
import { ViewFramesConfig } from "../types/Config.types";

export interface ViewFramesLoaderStorage {
    ViewFrames: ViewFramesStorageObject;
}

export type ViewFramesLoaderStorageKeyType = "ViewFramesLoader";

export const ViewFramesLoaderStorageKey: ViewFramesLoaderStorageKeyType = "ViewFramesLoader";

export const ViewFramesLoaderStorageDefault: ContextStorage<ViewFramesLoaderStorageKeyType, ViewFramesLoaderStorage> = {
    ViewFramesLoader: {
        ViewFrames: {},
    },
};

export const ERROR_VIEW_LOADER_VIEW_DOES_NOT_EXIST = new Error("ViewDoesNotExist");
export const ERROR_VIEW_FRAMES_LOADER_VIEW_CONFIG_DOES_NOT_EXIST = new Error("ViewConfigDoesNotExist");
export const ERROR_VIEW_FRAMES_LOADER_VIEW_DATA_DOES_NOT_EXIST = new Error("ViewDataDoesNotExist");

export class ViewFramesLoader extends BaseContext<ViewFramesLoaderStorage, ViewFramesLoaderStorageKeyType> {
    private loadQueue: ViewFramesQueueElement[] = [];
    private loadCanceler: Canceler | undefined;
    private loadInProgress: boolean = false;

    public async init() {
        window.VIEW_FRAMES = APP_CONFIG.views.reduce((viewFrames, view) => {
            viewFrames[view.id] = {
                Frames: [],
                FramesByApartment: [],
            };
            return viewFrames;
        }, {} as WindowViewFramesData);
        await this.parentSetStateAsync(() => ({
            ViewFrames: APP_CONFIG.views.reduce((viewFrames, view) => {
                viewFrames[view.id] = {
                    LoadState: { state: "idle" },
                };
                return viewFrames;
            }, {} as ViewFramesStorageObject),
        }));
        APP_CONFIG.views.forEach((view) => {
            this.loadQueueAdd(view.id);
        });
    }

    private getViewConfig(viewId: number) {
        const viewConfig = APP_CONFIG.views.find((e) => e.id === viewId);
        if (!viewConfig) throw ERROR_VIEW_FRAMES_LOADER_VIEW_CONFIG_DOES_NOT_EXIST;
        return viewConfig;
    }

    private loadStateSetProgress(id: number, state: ProgressState) {
        this.parentSetState((p) => ({
            ViewFrames: Object.keys(p.ViewFrames).reduce((viewFrames, viewFramesIdString) => {
                const viewFramesId = parseInt(viewFramesIdString);
                const viewFrame = p.ViewFrames[viewFramesId];
                viewFrames[viewFramesId] = {
                    LoadState: id === viewFramesId ? state : { ...viewFrame.LoadState },
                };
                return viewFrames;
            }, {} as ViewFramesStorageObject),
        }));
    }

    private async loadStateSetAllPendingToIdle() {
        await this.parentSetStateAsync((p) => ({
            ViewFrames: Object.keys(p.ViewFrames).reduce((viewFrames, viewFramesIdString) => {
                const viewFramesId = parseInt(viewFramesIdString);
                const viewFrame = p.ViewFrames[viewFramesId];
                viewFrames[viewFramesId] = {
                    LoadState: viewFrame.LoadState.state === "pending" ? { state: "idle" } : { ...viewFrame.LoadState },
                };
                return viewFrames;
            }, {} as ViewFramesStorageObject),
        }));
    }

    private async loadQueueProcess() {
        if (this.loadInProgress) return;
        if (this.loadQueue.length === 0) return;
        this.loadInProgress = true;

        const queueElement = this.loadQueue[0];

        try {
            const viewConfig = this.getViewConfig(queueElement.id);
            if (!window.DOLL_HOUSES[queueElement.id]) throw ERROR_VIEW_FRAMES_LOADER_VIEW_DATA_DOES_NOT_EXIST;

            this.loadStateSetProgress(queueElement.id, { state: "pending", percent: 0 });

            const response: AxiosResponse<ViewFramesConfig> = await axios({
                url: viewConfig.frameElementsUrl,
                responseType: "json",
                cancelToken: new axios.CancelToken((cancel) => {
                    this.loadCanceler = cancel;
                }),
                onDownloadProgress: (e: ProgressEvent) => {
                    this.loadStateSetProgress(queueElement.id, {
                        state: "pending",
                        percent: e.total > 0 ? Math.round((e.loaded * 100) / e.total) : 0,
                    });
                },
            });
            window.VIEW_FRAMES[queueElement.id].Frames = response.data;
            window.VIEW_FRAMES[queueElement.id].FramesByApartment = response.data.map((f) =>
                f.reduce((object, frameElement) => {
                    if (frameElement.A.T === "A") {
                        object[frameElement.A.I] = true;
                    }
                    return object;
                }, {} as ViewFrameByApartment)
            );

            this.loadStateSetProgress(queueElement.id, { state: "completed" });

            queueElement.resolve.forEach((r) => r());
        } catch (e) {
            if (axios.isCancel(e)) {
                return;
            }
            console.log(e);
            this.loadStateSetProgress(queueElement.id, { state: "error" });
            queueElement.reject.forEach((r) => r());
        }

        this.loadQueue.splice(0, 1);
        this.loadCanceler = undefined;
        this.loadInProgress = false;

        this.loadQueueProcess();
    }

    public async loadQueueAdd(id: number) {
        if (this.storage.ViewFrames[id] === undefined) throw ERROR_VIEW_LOADER_VIEW_DOES_NOT_EXIST;
        if (this.storage.ViewFrames[id].LoadState.state === "completed") return;

        const existingQueueElement = this.loadQueue.find((e) => e.id === id);

        if (existingQueueElement) {
            return new Promise((resolve, reject) => {
                existingQueueElement.resolve.push(resolve);
                existingQueueElement.reject.push(reject);
            });
        }

        return new Promise((resolve, reject) => {
            this.loadQueue.push({ id: id, resolve: [resolve], reject: [reject] });
            this.loadQueueProcess();
        });
    }

    public async loadQueueAddForce(id: number) {
        if (this.storage.ViewFrames[id] === undefined) throw ERROR_VIEW_LOADER_VIEW_DOES_NOT_EXIST;
        if (this.storage.ViewFrames[id].LoadState.state === "completed") return;

        const existingQueueElementIndex = this.loadQueue.findIndex((e) => e.id === id);

        if (existingQueueElementIndex === 0) {
            return new Promise((resolve, reject) => {
                this.loadQueue[existingQueueElementIndex].resolve.push(resolve);
                this.loadQueue[existingQueueElementIndex].resolve.push(reject);
            });
        }

        if (!!this.loadCanceler) this.loadCanceler();
        this.loadInProgress = false;

        await this.loadStateSetAllPendingToIdle();

        if (existingQueueElementIndex !== -1) {
            const existingQueueElement = this.loadQueue[existingQueueElementIndex];

            this.loadQueue.splice(existingQueueElementIndex, 1);

            return new Promise((resolve, reject) => {
                existingQueueElement.resolve.push(resolve);
                existingQueueElement.reject.push(reject);
                this.loadQueue.splice(0, 0, existingQueueElement);
                this.loadQueueProcess();
            });
        } else {
            return new Promise((resolve, reject) => {
                this.loadQueue.splice(0, 0, { id: id, resolve: [resolve], reject: [reject] });
                this.loadQueueProcess();
            });
        }
    }
}

// export class ViewFramesLoader e
