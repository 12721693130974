import React from "react";

import { BaseComponent } from "../../utils/BaseComponent";

import "./ApartmentList.scss";
import { Apartment, ApartmentsButton } from "../../types/Config.types";
import { availabilityToLabel } from "../../utils/BasicFunctions";
import { Link } from "react-router-dom";

interface Props {
    filters: React.ReactNode;
    apartments: Apartment[];
    currentButton: ApartmentsButton;
    apartmentHoverId: string | undefined;
    onApartmentOver: (apartmentId: string) => void;
    onApartmentOut: (apartmentId: string) => void;
    navOpen: () => void;
    openApartment: (apartmentId: string) => void;
}

interface State {
    viewTable: boolean;
}

export default class ApartmentList extends BaseComponent<Props, State> {
    state: State = {
        viewTable: false,
    };

    render() {
        return (
            <div className="apartment-list">
                <div className="apartment-list__header">
                    <div className="apartment-list__header-bar">
                        <div className="apartment-list__header-title">
                            <b>Mieszkania</b>
                        </div>
                        <div className="apartment-list__header-icon" onClick={() => this.context.Common.navOpen()}>
                            menu
                        </div>
                    </div>
                    <div className="apartment-list__header-building">{this.props.currentButton.title}</div>
                    <div className="apartment-list__header-view-mode">
                        <div className="apartment-list__header-free-apartments">
                            <b>{this.props.apartments.length}</b> mieszkań
                        </div>
                        <div
                            className="apartment-list__header-view-mode-button"
                            onClick={() => this.setState((p) => ({ viewTable: !p.viewTable }))}
                        >
                            <div className="apartment-list__header-view-mode-icon">
                                {!this.state.viewTable ? "view_column" : "view_module"}
                            </div>
                            <div className="apartment-list__header-view-mode-label">
                                {!this.state.viewTable ? "Widok listy" : "Widok kart"}
                            </div>
                        </div>
                    </div>
                    {this.props.filters}
                </div>
                {this.state.viewTable ? (
                    <div className="apartment-list__table-wrapper">
                        <table className="apartment-list__table">
                            <thead>
                                <tr>
                                    <th>Mieszkanie</th>
                                    <th>Budynek</th>
                                    <th>Pow</th>
                                    <th>Piętro</th>
                                    <th>Liczba Pokoi</th>
                                    <th></th>
                                    {/* <th>Status</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.apartments.map((a) => (
                                    <tr
                                        key={a.id}
                                        onClick={() => this.props.openApartment(a.id)}
                                        className={`${a.id === this.props.apartmentHoverId ? "is-hover" : ""}`}
                                        onMouseOver={() => this.props.onApartmentOver(a.id)}
                                        onMouseOut={() => this.props.onApartmentOut(a.id)}
                                    >
                                        <td>{a.name}</td>
                                        <td>{a.building}</td>
                                        <td>{a.area}</td>
                                        <td>{a.floor}</td>
                                        <td>{a.rooms}</td>
                                        <td>
                                            <button
                                                className={`button icon ${
                                                    this.context.Compare.isSelected(a.id) ? "is-active" : ""
                                                }`}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    this.context.Compare.change(a.id);
                                                }}
                                                title="Dodaj do porównania"
                                            >
                                                bar_chart
                                            </button>
                                        </td>
                                        {/* <td>
                                    {apartment.availability === 1
                                        ? "Dostępne"
                                        : apartment.availability === 2
                                        ? "Rezerwacja"
                                        : "Sprzedane"}
                                </td> */}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <div className="apartment-list__list">
                        {this.props.apartments.map((a, index) => (
                            <div
                                className={`apartment-list__card ${
                                    a.id === this.props.apartmentHoverId ? "is-hover" : ""
                                }`}
                                key={a.id}
                                onMouseOver={() => this.props.onApartmentOver(a.id)}
                                onMouseOut={() => this.props.onApartmentOut(a.id)}
                                onClick={() => this.props.openApartment(a.id)}
                            >
                                <div className="apartment-list__card-content">
                                    <div className="apartment-list__card-left">
                                        <div className="apartment-list__card-title">{a.name}</div>
                                        <div className="apartment-list__card-availability">
                                            {availabilityToLabel(a.availability)}
                                        </div>
                                        <div className="apartment-list__card-prop">
                                            <div className="apartment-list__card-prop-label">Powierzchnia</div>
                                            <div className="apartment-list__card-prop-value">
                                                {a.area} m<sup>2</sup>
                                            </div>
                                        </div>
                                        <div className="apartment-list__card-prop">
                                            <div className="apartment-list__card-prop-label">Liczba Pokoi</div>
                                            <div className="apartment-list__card-prop-value">{a.rooms}</div>
                                        </div>
                                        <div className="apartment-list__card-prop">
                                            <div className="apartment-list__card-prop-label">Piętro</div>
                                            <div className="apartment-list__card-prop-value">{a.floor}</div>
                                        </div>
                                    </div>
                                    <div className="apartment-list__card-right">
                                        <div className="apartment-list__card-layout">
                                            <img src={a.layout} />
                                        </div>
                                        <div
                                            className={`apartment-list__card-compare-button ${
                                                this.context.Compare.isSelected(a.id) ? "is-selected" : ""
                                            }`}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                this.context.Compare.change(a.id);
                                            }}
                                            title="Dodaj do porównania"
                                        >
                                            bar_chart
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}

                        <div className="apartment-list__card-fake"></div>
                        <div className="apartment-list__card-fake"></div>
                        <div className="apartment-list__card-fake"></div>
                        <div className="apartment-list__card-fake"></div>
                    </div>
                )}
                {this.context.Compare.storage.apartmentIds.length > 0 ? (
                    <div className="apartment-list__compare-button-wrapper">
                        <Link to="/Compare" className="button is-active">
                            Porównaj mieszkania
                        </Link>
                    </div>
                ) : null}
            </div>
        );
    }
}
