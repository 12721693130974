import { BaseContext, ContextStorage } from "./Base";

export interface CommonStorage {
    navIsOpen: boolean;
    navOpenedScrollTop: number;
}

export const CommonStorageKey = "Common";

export type CommonStorageKeyType = typeof CommonStorageKey;

export const CommonStorageDefault: ContextStorage<CommonStorageKeyType, CommonStorage> = {
    [CommonStorageKey]: {
        navIsOpen: false,
        navOpenedScrollTop: 0,
    },
};

export class Common extends BaseContext<CommonStorage, CommonStorageKeyType> {
    public async init() {}

    get navIsOpen() {
        return this.storage.navIsOpen;
    }

    public navOpen() {
        this.parentSetState(
            () => ({ navIsOpen: true, navOpenedScrollTop: window.scrollY }),
            () => {
                window.scrollTo(window.scrollX, 0);
            }
        );
    }

    public navClose(noScroll?: boolean) {
        this.parentSetState(
            () => ({ navIsOpen: false }),
            () => {
                if (!noScroll) {
                    window.scrollTo(window.scrollX, this.storage.navOpenedScrollTop);
                }
            }
        );
    }
}
