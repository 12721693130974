import React from "react";

import { BaseComponent } from "../utils/BaseComponent";
import Nav from "../components/Nav";
import logo from "../assets/logo.png";

import "./Compare.scss";
import { Apartment } from "../types/Config.types";
import { availabilityToLabel } from "../utils/BasicFunctions";
import { Link } from "react-router-dom";

interface TableElement {
    label: string;
    f: (a: Apartment) => string | number | React.ReactNode;
}

export default class Compare extends BaseComponent {
    private tableDefinition: TableElement[] = [
        { label: "Budynek", f: (a) => a.building },
        { label: "Piętro", f: (a) => a.floor },
        {
            label: "Powierzchnia",
            f: (a) => (
                <>
                    {a.area} m<sup>2</sup>
                </>
            ),
        },
        { label: "Liczba pokoi", f: (a) => a.rooms },
        { label: "Status", f: (a) => availabilityToLabel(a.availability) },
        { label: "", f: (a) => <img className="compare__layout" src={a.layout} alt="" /> },
        {
            label: "",
            f: (a) => (
                <div className="compare__td-center">
                    <Link className="button" to={`/ApartmentCard/${a.id}`}>
                        Przejdź
                    </Link>
                    <button className="button icon" onClick={() => this.context.Compare.remove(a.id)}>
                        delete
                    </button>
                </div>
            ),
        },
    ];

    render() {
        return (
            <div className="compare">
                <div className="compare__viewer">
                    <img src={logo} className="start__logo" />
                    <div className="compare__title">Porównaj mieszkania</div>
                    {this.apartments.length === 0 ? (
                        <div className="compare__empty">
                            <div className="compare__empty-text">
                                Nie wybrano żadnego mieszkania do porównania.
                                <br />
                                Wybierz mieszkania do porównania.
                            </div>
                            <Link to="/Apartments" className="button">
                                Wyszukiwarka mieszkań
                            </Link>
                        </div>
                    ) : (
                        <div className="compare__list">
                            <table className="compare__table">
                                <thead>
                                    <tr>
                                        <th>&nbsp;</th>
                                        {this.apartments.map((a) => (
                                            <th key={a.id}>{a.name}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.tableDefinition.map((d, index) => (
                                        <tr key={index}>
                                            <td>{d.label}</td>
                                            {this.apartments.map((a) => (
                                                <td key={a.id}>{d.f(a)}</td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>
                <div className="compare__sidebar-wrapper"></div>
                <div className="compare__sidebar">
                    <Nav showNavClose={false} />
                </div>
            </div>
        );
    }

    get apartments() {
        return this.context.ApartmentConfigLoader.storage.Apartments.filter((a) =>
            this.context.Compare.storage.apartmentIds.includes(a.id)
        );
    }
}
