import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import { BaseComponent } from "../utils/BaseComponent";

import Start from "./Start";
import Apartments from "./Apartments";
import ApartmentCard from "./ApartmentCard";
import Compare from "./Compare";

import Nav from "../components/Nav";

export const ROUTES = {
    Apartments: "/Apartments",
    ApartmentCard: "/ApartmentCard/:apartmentId",
};

export default class Router extends BaseComponent {
    render() {
        return (
            <>
                <div className={`router ${this.context.Common.navIsOpen ? "no-scroll" : ""}`}>
                    <div
                        className="content"
                        style={{
                            marginTop: this.context.Common.navIsOpen
                                ? `-${this.context.Common.storage.navOpenedScrollTop}px`
                                : 0,
                        }}
                    >
                        <Switch>
                            <Route path="/Start" component={Start} />
                            <Route path="/Compare" component={Compare} />
                            <Route path={ROUTES.Apartments} component={Apartments} />
                            <Route path={ROUTES.ApartmentCard} component={ApartmentCard} />
                            <Redirect path="*" to="/Start" />
                        </Switch>
                    </div>
                </div>
                {this.context.Common.navIsOpen ? <Nav showNavClose={true} /> : null}
            </>
        );
    }
}
