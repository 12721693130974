import { createContext } from "react";

import App from "../App";
import {
    DollHouseLoader,
    DollHouseLoaderStorage,
    DollHouseLoaderStorageDefault,
    DollHouseLoaderStorageKey,
} from "./DollHouseLoader";

import { ViewLoader, ViewLoaderStorage, ViewLoaderStorageDefault, ViewLoaderStorageKey } from "./ViewLoader";
import {
    ApartmentConfigLoader,
    ApartmentConfigLoaderStorage,
    ApartmentConfigLoaderStorageDefault,
    ApartmentConfigLoaderStorageKey,
} from "./ApartmentConfigLoader";
import {
    ViewFramesLoader,
    ViewFramesLoaderStorage,
    ViewFramesLoaderStorageKey,
    ViewFramesLoaderStorageDefault,
} from "./ViewFramesLoader";

import { Compare, CompareStorage, CompareStorageDefault, CompareStorageKey } from "./Compare";
import { Common, CommonStorageKey, CommonStorage, CommonStorageDefault } from "./Common";

export interface ContextApp {
    ApartmentConfigLoader: ApartmentConfigLoader;
    DollHouseLoader: DollHouseLoader;
    ViewLoader: ViewLoader;
    ViewFramesLoader: ViewFramesLoader;
    Compare: Compare;
    Common: Common;
}

export interface ContextAppState {
    ApartmentConfigLoader: ApartmentConfigLoaderStorage;
    DollHouseLoader: DollHouseLoaderStorage;
    ViewLoader: ViewLoaderStorage;
    ViewFramesLoader: ViewFramesLoaderStorage;
    Compare: CompareStorage;
    [CommonStorageKey]: CommonStorage;
}

export const ContextAppStateDefault = {
    ...ApartmentConfigLoaderStorageDefault,
    ...DollHouseLoaderStorageDefault,
    ...ViewLoaderStorageDefault,
    ...ViewFramesLoaderStorageDefault,
    ...CompareStorageDefault,
    ...CommonStorageDefault,
};

export function ContextAppValues(parent: App): ContextApp {
    return {
        ApartmentConfigLoader: new ApartmentConfigLoader(parent, ApartmentConfigLoaderStorageKey),
        DollHouseLoader: new DollHouseLoader(parent, DollHouseLoaderStorageKey),
        ViewLoader: new ViewLoader(parent, ViewLoaderStorageKey),
        ViewFramesLoader: new ViewFramesLoader(parent, ViewFramesLoaderStorageKey),
        Compare: new Compare(parent, CompareStorageKey),
        Common: new Common(parent, CommonStorageKey),
    };
}
export const ContextApp = createContext<ContextApp>({} as ContextApp);
