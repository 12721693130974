import React from "react";
import { BaseComponent } from "../utils/BaseComponent";
import { Link, NavLink } from "react-router-dom";

import "./Nav.scss";

interface Props {
    showNavClose: boolean;
    navClose?: () => void;
}

export default class Nav extends BaseComponent<Props> {
    render() {
        return (
            <div className="nav">
                <div className="nav__center">
                    <div className="nav__top">
                        {this.props.showNavClose ? (
                            <div className="nav__header">
                                <div
                                    className="nav__header-close"
                                    onClick={() => {
                                        this.context.Common.navClose();
                                        this.props.navClose && this.props.navClose();
                                    }}
                                >
                                    close
                                </div>
                            </div>
                        ) : null}
                        <div className="nav__title">
                            Osiedle
                            <br />
                            Złota Ostoja
                            <br />
                            <div className="nav__title-small">Etap 3</div>
                        </div>
                        <div className="nav__caption">
                            Przestrzeń&nbsp;&nbsp;&nbsp;&nbsp;
                            <br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;możliwości
                        </div>
                    </div>
                    <div className="nav__middle">
                        <NavLink to="/Start" className="nav__link" onClick={() => this.context.Common.navClose(true)}>
                            Start
                        </NavLink>
                        <div className="nav__link">Lokalizacja</div>
                        <NavLink
                            to="/Apartments"
                            className="nav__link"
                            onClick={() => this.context.Common.navClose(true)}
                        >
                            Mieszkania
                        </NavLink>
                        <div className="nav__link">O Inwestycji</div>
                        <NavLink to="/Compare" className="nav__link" onClick={() => this.context.Common.navClose(true)}>
                            Porównaj
                        </NavLink>
                    </div>
                    <div className="nav__bottom">Demo technologiczne 3D Estate Sp. z o.o.</div>
                </div>
            </div>
        );
    }
}
