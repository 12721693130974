import React from "react";
import { ContextApp } from "../context";

export abstract class BaseComponent<P = {}, S = {}, SS = any> extends React.Component<P, S, SS> {
    static contextType = ContextApp;
    public context!: React.ContextType<typeof ContextApp>;

    protected _isMounted = false;

    get isMounted() {
        return this._isMounted;
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }
}
