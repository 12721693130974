import React from "react";

type Callback = () => void;

export type ContextStorage<TStorageKey extends string, TStorage> = {
    [T in TStorageKey]: TStorage;
};

interface ParentState<TStorageKey extends string, TStorage> {
    context: ContextStorage<TStorageKey, TStorage>;
}

export class Parent<TStorageKey extends string, TStorage> extends React.Component<
    any,
    ParentState<TStorageKey, TStorage>
> {}

export async function ParentSetState<TStorageKey extends string, TStorage>(
    parent: Parent<TStorageKey, TStorage>,
    storageKey: TStorageKey,
    stateFunction: <K extends keyof TStorage>(storage: TStorage) => Pick<TStorage, K> | TStorage | null
): Promise<void> {
    return new Promise(resolve => {
        parent.setState(
            p => {
                const newStorageState: TStorage = { ...p.context[storageKey], ...stateFunction(p.context[storageKey]) };
                return {
                    context: {
                        ...p.context,
                        [storageKey]: newStorageState
                    }
                };
            },
            () => resolve()
        );
    });
}

export class BaseContext<TStorage, TStorageKey extends string> {
    protected parent: Parent<TStorageKey, TStorage>;
    protected parentStorageKey: TStorageKey;

    constructor(parent: Parent<TStorageKey, TStorage>, parentStorageKey: TStorageKey) {
        this.parent = parent;
        this.parentStorageKey = parentStorageKey;
    }

    get storage(): TStorage {
        return this.parent.state.context[this.parentStorageKey];
    }

    protected parentSetState(
        stateFunction: (storage: TStorage) => Partial<TStorage> | TStorage | null,
        callback?: Callback
    ) {
        this.parent.setState(
            p => {
                const newStorageState: TStorage = {
                    ...p.context[this.parentStorageKey],
                    ...stateFunction(p.context[this.parentStorageKey])
                };
                return {
                    context: {
                        ...p.context,
                        [this.parentStorageKey]: newStorageState
                    }
                };
            },
            () => !!callback && callback()
        );
    }

    protected async parentSetStateAsync(stateFunction: (storage: TStorage) => Partial<TStorage> | TStorage | null) {
        return new Promise(resolve =>
            this.parent.setState(
                p => {
                    const newStorageState: TStorage = {
                        ...p.context[this.parentStorageKey],
                        ...stateFunction(p.context[this.parentStorageKey])
                    };
                    return {
                        context: {
                            ...p.context,
                            [this.parentStorageKey]: newStorageState
                        }
                    };
                },
                () => resolve()
            )
        );
    }
}
