import React from "react";

import { FieldWrapperProps } from "./FieldWrapper";

export interface FieldBaseProps<T> extends FieldWrapperProps {
    value: T;
    onChange: (value: T) => void;
}

export default class FieldBase<T, Props = {}, State = {}> extends React.Component<FieldBaseProps<T> & Props, State> {
    get fieldWrapperProps(): FieldWrapperProps {
        return {
            label: this.props.label
        };
    }

    get value(): T {
        return this.props.value;
    }

    protected onChange(value: T) {
        this.props.onChange(value);
    }
}
