import React from "react";

import { BaseComponent } from "../../utils/BaseComponent";

import { RangeValue } from "../../types/Generic.types";

import Range from "../../form/Range";
import Check from "../../form/Check";

import "./ApartmentFilters.scss";

export interface FilterFields {
    props: { [id: number]: boolean };
    status: 0 | 1 | 2 | 3;
    area: RangeValue;
    floor: RangeValue;
    rooms: RangeValue;
    visibleOnly: boolean;
}

interface Props {
    filters: FilterFields;
    filtersSet: (setFunction: (filters: FilterFields) => Partial<FilterFields>) => void;
}

export default class ApartmentFilters extends BaseComponent<Props> {
    render() {
        const filters = this.props.filters;
        const filtersSet = this.props.filtersSet;
        return (
            <div className="apartment-filters">
                <div className="apartment-filters__header"></div>
                <div className="apartment-filters__columns">
                    <div className="apartment-filters__column">
                        {this.context.ApartmentConfigLoader.propDefinitions.map((pd) => {
                            if (!pd.filter) return null;

                            switch (pd.type) {
                                case "boolean":
                                    return (
                                        <Check
                                            key={pd.id}
                                            value={filters.props[pd.id]}
                                            onChange={(value) =>
                                                filtersSet((p) => ({ props: { ...p.props, [pd.id]: value } }))
                                            }
                                            label={pd.name}
                                        />
                                    );

                                default:
                                    return null;
                            }
                        })}
                        <div className="apartment-filters__column-spacer"></div>
                        <Check
                            value={filters.status === 1}
                            onChange={(value) => filtersSet((p) => ({ status: value ? 1 : 0 }))}
                            label="dostępne"
                        />
                        <Check
                            value={filters.status === 2}
                            onChange={(value) => filtersSet((p) => ({ status: value ? 2 : 0 }))}
                            label="rezerwacja"
                        />
                        <Check
                            value={filters.status === 3}
                            onChange={(value) => filtersSet((p) => ({ status: value ? 3 : 0 }))}
                            label="sprzedane"
                        />
                        <div className="apartment-filters__column-spacer"></div>
                        <Check
                            value={filters.visibleOnly}
                            onChange={(v) => filtersSet(() => ({ visibleOnly: v }))}
                            label="Tylko widoczne apartamenty"
                        />
                    </div>
                    <div className="apartment-filters__column right">
                        <Range
                            value={undefined}
                            label="Powierzchnia"
                            valueMin={filters.area.min}
                            valueMax={filters.area.max}
                            min={APP_CONFIG.filtersRangeValues.area.min}
                            max={APP_CONFIG.filtersRangeValues.area.max}
                            setValueMin={(v) =>
                                filtersSet((p) => ({
                                    area: { ...p.area, min: v || APP_CONFIG.filtersRangeValues.area.min },
                                }))
                            }
                            setValueMax={(v) =>
                                filtersSet((p) => ({
                                    area: { ...p.area, max: v || APP_CONFIG.filtersRangeValues.area.max },
                                }))
                            }
                            onChange={() => {}}
                        />
                        <Range
                            value={undefined}
                            label="Piętro"
                            valueMin={filters.floor.min}
                            valueMax={filters.floor.max}
                            min={APP_CONFIG.filtersRangeValues.floor.min}
                            max={APP_CONFIG.filtersRangeValues.floor.max}
                            setValueMin={(v) =>
                                filtersSet((p) => ({
                                    floor: { ...p.floor, min: v || APP_CONFIG.filtersRangeValues.floor.min },
                                }))
                            }
                            setValueMax={(v) =>
                                filtersSet((p) => ({
                                    floor: { ...p.floor, max: v || APP_CONFIG.filtersRangeValues.floor.max },
                                }))
                            }
                            onChange={() => {}}
                        />
                        <Range
                            value={undefined}
                            label="Liczba pokoi"
                            valueMin={filters.rooms.min}
                            valueMax={filters.rooms.max}
                            min={APP_CONFIG.filtersRangeValues.rooms.min}
                            max={APP_CONFIG.filtersRangeValues.rooms.max}
                            setValueMin={(v) =>
                                filtersSet((p) => ({
                                    rooms: { ...p.rooms, min: v || APP_CONFIG.filtersRangeValues.rooms.min },
                                }))
                            }
                            setValueMax={(v) =>
                                filtersSet((p) => ({
                                    rooms: { ...p.rooms, max: v || APP_CONFIG.filtersRangeValues.rooms.max },
                                }))
                            }
                            onChange={() => {}}
                        />
                    </div>
                </div>
            </div>
        );
    }
}
