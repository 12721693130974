import React from "react";
import {} from "react-router";

import { BasePlugin } from "../../plugin/Base";
import { ViewScene } from "../../context/ViewLoader.types";
import { Defer, RemoveChildrenFromHTMLElement, FrameDistance, FrameInRange } from "../../utils/BasicFunctions";
import { Parent } from "../../plugin/Base";

export type ViewerPluginStorageKeyType = "ViewerPlugin";
export const ViewerPluginStorageKey: ViewerPluginStorageKeyType = "ViewerPlugin";

export interface ViewerPluginStorage {
    viewId: number;
    viewScene: ViewScene;
    hide: boolean;
    frameIndex: number;
    staticIndex: number;
    visibleElements: boolean;
    scale: number;
    hoverElementIndex: string | undefined;
}

export const ViewerPluginStorageInit: ViewerPluginStorage = {
    viewId: APP_CONFIG.views[0].id,
    viewScene: "Day",
    hide: true,
    frameIndex: 0,
    staticIndex: 0,
    visibleElements: true,
    scale: 1,
    hoverElementIndex: undefined,
};

export interface ViewerPluginInitValues {
    frameIndex?: number;
    viewId?: number;
    viewScene?: ViewScene;
    visibleElements?: boolean;
    scale?: number;
}

export type HistorySearchChange = (search: { [key: string]: string | number }) => void;

export class ViewerPlugin extends BasePlugin<ViewerPluginStorageKeyType, ViewerPluginStorage> {
    public imagesDayRef = React.createRef<HTMLDivElement>();
    public imagesNightRef = React.createRef<HTMLDivElement>();

    public animateInProgress: boolean = false;

    private historySearchPush: HistorySearchChange;

    constructor(
        parent: Parent<ViewerPluginStorageKeyType, ViewerPluginStorage>,
        parentStorageKey: ViewerPluginStorageKeyType,
        historySearchPush: HistorySearchChange
    ) {
        super(parent, parentStorageKey);
        this.historySearchPush = historySearchPush;
    }

    get viewLoader() {
        return this.parent.context.ViewLoader;
    }

    get currentViewConfig() {
        return APP_CONFIG.views.find((e) => e.id === this.storage.viewId);
    }

    get frameElements() {
        return !!this.parent.context.ViewFramesLoader.storage.ViewFrames[this.storage.viewId] &&
            this.parent.context.ViewFramesLoader.storage.ViewFrames[this.storage.viewId].LoadState.state === "completed"
            ? window.VIEW_FRAMES[this.storage.viewId].Frames[this.storage.frameIndex] ?? []
            : [];
    }

    public async init(initValues?: ViewerPluginInitValues) {
        await this.parentSetStateAsync((p) => ({ ...p, ...initValues }));
        await this.parent.context.ViewLoader.unzipQueueAddForce(this.storage.viewId, this.storage.viewScene);
        this.loadImages();
        await this.showCurrentView();
        this.parent.context.ViewFramesLoader.loadQueueAddForce(this.storage.viewId);
    }

    public async openView(id: number) {
        this.historySearchPush({ view: id.toString(), frame: 0 });
        await this.hideCurrentView();
        this.animateAbort();
        this.clearImages();
        this.parentSetState((p) => ({ viewId: id, frameIndex: 0, staticIndex: 0, scale: 1 }));
        await this.parent.context.ViewLoader.unzipQueueAddForce(id, this.storage.viewScene);
        this.loadImages();
        await this.showCurrentView();
        this.parent.context.ViewFramesLoader.loadQueueAddForce(id);
    }

    public async changeScene() {
        const newScene: ViewScene = this.storage.viewScene === "Day" ? "Night" : "Day";
        if (this.viewLoader.storage.Views[this.storage.viewId][newScene].UnzipState.state === "completed") {
            this.loadImagesScene(newScene);
            this.parentSetState((p) => ({ viewScene: newScene }));
            this.clearImagesScene(newScene === "Day" ? "Night" : "Day", 1000);
            await this.showCurrentView();
        } else {
            await this.parentSetStateAsync((p) => ({ viewScene: newScene, hide: true }));
            this.clearImagesScene(newScene === "Day" ? "Night" : "Day", 1000);
            await this.parent.context.ViewLoader.unzipQueueAddForce(this.storage.viewId, this.storage.viewScene);
            this.loadImages();
            await this.showCurrentView();
        }
        this.historySearchPush({ scene: newScene });
    }

    public setIndex(index: number) {
        this.parentSetState(() => ({ frameIndex: index }));
    }

    public changeElementVisibility() {
        this.parentSetState((p) => ({ visibleElements: !p.visibleElements }));
    }

    public animateToFrame(index: number) {
        if (this.animateInProgress) return;
        this.animateInProgress = true;
        this.animateProcess(index);
    }

    public animateAbort() {
        this.animateInProgress = false;
    }

    public hoverElement(id: string) {
        this.parentSetState(() => ({ hoverElementIndex: id }));
    }

    public hoverElementOut(id: string) {
        this.parentSetState((p) => ({
            hoverElementIndex: p.hoverElementIndex === id ? undefined : p.hoverElementIndex,
        }));
    }

    private animateProcess(indexTo: number) {
        if (!this.animateInProgress) return;
        if (this.storage.frameIndex === indexTo) {
            this.animateInProgress = false;
            this.moveEnded();
            return;
        }
        const direction =
            FrameDistance(this.storage.frameIndex, indexTo, this.currentViewConfig?.framesCount || 0) < 0 ? -1 : 1;
        this.parentSetState((p) => ({
            frameIndex: FrameInRange(p.frameIndex + direction, this.currentViewConfig?.framesCount || 0),
        }));

        window.setTimeout(() => this.animateProcess(indexTo), 30);
    }

    public move(direction: 1 | -1) {
        const delta = Math.round((this.currentViewConfig?.framesCount || 60) / 6);
        const frameIndex = FrameInRange(
            this.storage.frameIndex + direction * delta,
            this.currentViewConfig?.framesCount || 0
        );

        this.animateToFrame(frameIndex);
    }

    public moveEnded() {
        this.historySearchPush({ frame: this.storage.frameIndex });
        this.parentSetState(() => ({ staticIndex: this.storage.frameIndex }));
    }

    public scale(direction: 1 | -1) {
        this.parentSetState((p) => {
            let newScale = p.scale + direction * 0.2;
            newScale = newScale < 1 ? 1 : newScale > 2 ? 2 : newScale;
            return {
                scale: newScale,
            };
        });
    }

    private async hideCurrentView() {
        await this.parentSetStateAsync(() => ({ hide: true }));
        await Defer(300);
    }

    private async showCurrentView() {
        await this.parentSetStateAsync(() => ({ hide: false }));
    }

    private clearImages() {
        const imagesDay = this.imagesDayRef.current;
        const imagesNight = this.imagesNightRef.current;

        if (!!imagesDay) RemoveChildrenFromHTMLElement(imagesDay);
        if (!!imagesNight) RemoveChildrenFromHTMLElement(imagesNight);
    }

    private async clearImagesScene(scene: ViewScene, defer?: number) {
        if (!!defer) await Defer(defer);
        if (this.storage.viewScene === scene) return;
        const container = scene === "Day" ? this.imagesDayRef.current : this.imagesNightRef.current;
        if (!container) return;
        RemoveChildrenFromHTMLElement(container);
    }

    private loadImagesScene(scene: ViewScene) {
        const container = scene === "Day" ? this.imagesDayRef.current : this.imagesNightRef.current;
        if (!container) return;
        container.append(
            ...window.VIEWS[this.storage.viewId][scene].Images.map((image) => {
                image.Image.className = `image-${image.Index}`;
                image.Image.draggable = false;
                return image.Image;
            })
        );
    }

    private loadImages() {
        this.loadImagesScene(this.storage.viewScene);
    }
}
