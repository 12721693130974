import { BaseContext, ContextStorage } from "./Base";
import axios, { AxiosResponse } from "axios";
import { Apartment, ApartmentsConfig, ApartmentPropDefinition } from "../types/Config.types";

export interface CompareStorage {
    apartmentIds: string[];
}

export type CompareStorageKeyType = "Compare";

export const CompareStorageKey: CompareStorageKeyType = "Compare";

export const CompareStorageDefault: ContextStorage<CompareStorageKeyType, CompareStorage> = {
    Compare: {
        apartmentIds: [],
    },
};

const CompareLocalStorageKey = "CompareApartmentIds";

export class Compare extends BaseContext<CompareStorage, CompareStorageKeyType> {
    public async init() {
        this.localStorageLoad();
    }

    public async add(apartmentId: string) {
        await this.parentSetStateAsync((p) => {
            const t = [...p.apartmentIds];
            if (t.findIndex((e) => e === apartmentId) !== -1) {
                t.push(apartmentId);
            }
            return { apartmentIds: t };
        });
        this.localStorageSave();
    }

    public async remove(apartmentId: string) {
        await this.parentSetStateAsync((p) => {
            const t = [...p.apartmentIds];
            const index = t.findIndex((e) => e === apartmentId);
            if (index !== -1) {
                t.splice(index, 1);
            }

            return { apartmentIds: t };
        });
        this.localStorageSave();
    }

    public async change(apartmentId: string) {
        await this.parentSetStateAsync((p) => {
            const t = [...p.apartmentIds];
            const index = t.findIndex((e) => e === apartmentId);
            if (index !== -1) {
                t.splice(index, 1);
            } else {
                t.push(apartmentId);
            }

            return { apartmentIds: t };
        });
        this.localStorageSave();
    }

    public isSelected(apartmentId: string): boolean {
        return this.storage.apartmentIds.findIndex((e) => e === apartmentId) !== -1;
    }

    private localStorageSave() {
        localStorage.setItem(CompareLocalStorageKey, JSON.stringify(this.storage.apartmentIds));
    }

    private localStorageLoad() {
        const data = localStorage.getItem(CompareLocalStorageKey);
        if (!!data) {
            try {
                const t: string[] = JSON.parse(data);
                this.parentSetState(() => ({ apartmentIds: t }));
            } catch (e) {}
        }
    }
}
