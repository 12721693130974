import React from "react";
import { BrowserRouter } from "react-router-dom";
import { ContextAppState, ContextAppStateDefault, ContextAppValues, ContextApp } from "./context";

import Start from "./areas/Start";
import Router from "./areas/Router";

interface AppState {
    context: ContextAppState;
}

export default class App extends React.Component<{}, AppState> {
    state: AppState = {
        context: ContextAppStateDefault,
    };

    private contextValues = ContextAppValues(this);

    componentDidMount() {
        this.contextValues.ViewLoader.init();
        this.contextValues.DollHouseLoader.init();
        this.contextValues.ViewFramesLoader.init();
        this.contextValues.ApartmentConfigLoader.init();
        this.contextValues.Compare.init();
    }

    render() {
        return (
            <ContextApp.Provider value={this.contextValues}>
                <BrowserRouter>
                    <Router />
                </BrowserRouter>
            </ContextApp.Provider>
        );
    }
}
