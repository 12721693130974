import React from "react";

import "./Switch.scss";

interface Props {
    state: boolean;
    iconOn: string;
    iconOff: string;
    changeState: () => void;
}

const ViewerSwitch: React.FC<Props> = ({ state, iconOn, iconOff, changeState }) => (
    <div className={`viewer-switch ${state ? "on" : "off"}`} onClick={() => changeState()}>
        <div className="viewer-switch__icon left">{iconOn}</div>
        <div className="viewer-switch__icon right">{iconOff}</div>
    </div>
);

export default ViewerSwitch;
